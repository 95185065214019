import React from 'react';
import './DropdownComponent.css';

const DropdownComponent = ({ options, selectedOption, onChange }) => {
    return (
        <div className="dropdown-container">
            <select value={selectedOption} onChange={(e) => onChange(e.target.value)}>
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropdownComponent;
