import React from "react";
import './Loading.css';

export const Loading = (props) => {
    return (
        <div className="loading-spinner">
            <div className="spinner"></div>
            <p>Loading {props.value}...</p>
        </div>
    )
}
