import React, { useState, useEffect } from 'react';
import { getGroups } from "../../../utils/apiService";
import './GroupList.css'
import {Loading} from "../../common/Loading/Loading";

function GroupList() {
    const [groups, setGroups] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchGroups() {
            try {
                const response = await getGroups();
                if (response && response.data && Array.isArray(response.data.groups)) {
                    setGroups(response.data.groups);
                } else {
                    console.error('Unexpected data structure:', response);
                    setError('Unexpected data structure received from the server');
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
                setError('Failed to fetch groups. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        }
        fetchGroups();
    }, []);

    if (isLoading) {
        return (
            <Loading value={"groups"}/>
        );
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="group-list">
            <h1>Group List</h1>
            {groups.length > 0 ? (
                groups.map(group => (
                    <div key={group.id} className="group-card">
                        {group.users && group.users.length > 0 ? (
                            <details>
                                <summary>{group.name}</summary>
                                <div className="users-list">
                                    <h3>Users:</h3>
                                    <ul>
                                        {group.users.map(user => (
                                            <li key={user.id}>
                                                <strong>{user.name}</strong>
                                                <br />
                                                Email: {user.email}
                                                <br />
                                                UPN: {user.upn}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </details>
                        ) : (
                            <h2>{group.name}</h2>
                        )}
                    </div>
                ))
            ) : (
                <p>No groups available</p>
            )}
        </div>
    );
}

export default GroupList;