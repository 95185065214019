import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';

import './Home.css';
import PieChart from "../../components/charts/PieChart/PieChart";
import GroupList from "../../components/data/GroupList/GroupList";

const Home = () => {
    const { instance } = useMsal();

    const handleLoginRedirect = () => {
        instance.loginRedirect().catch((error) => console.log(error));
    };

    return (
        <div className="home-container">
            <UnauthenticatedTemplate>
                <h2>Welcome to HRIT Automation</h2>
                <Button variant="outline-dark" onClick={handleLoginRedirect} className="sign-in-button">
                    Sign in
                </Button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <h2>Welcome to HRIT Automation</h2>
                <div className="content">
                    <div className="pie-charts">
                        <PieChart />
                    </div>
                    <div className="group-list-container">
                        <GroupList />
                    </div>
                </div>
            </AuthenticatedTemplate>
        </div>
    );
};

export default Home;