import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './PieChart.css';
import { getCounts } from '../../../utils/apiService';
import axios from "axios"; // Import the API service

const PieChart = () => {
    const [data, setData] = useState({
        labels: ['Joiners', 'Movers', 'Leavers'],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getCounts();
                const { joiners, movers, leavers } = response.data;

                setData({
                    labels: ['Joiners', 'Movers', 'Leavers'],
                    datasets: [
                        {
                            data: [joiners, movers, leavers],
                            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                        },
                    ],
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, []);

    return (
        <div className="pie-chart-container">
            <Pie data={data} />
        </div>
    );
};

export default PieChart;