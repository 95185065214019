import { AppConfigurationClient } from "@azure/app-configuration";
import { LogLevel } from '@azure/msal-browser';

const connectionString = "Endpoint=https://hrit-automation.azconfig.io;Id=3Wpr;Secret=1LRd8wYDrot45VnRArCDLtRb4otSYuYSSyF3caCi06Y=";

const client = new AppConfigurationClient(connectionString);

export const getConfigValue = async (key, alternate="dashboard") => {
    const setting = await client.getConfigurationSetting({ key: alternate+`${key}` });
    return setting.value;
};

const fetchConfig = async () => {
    const settings = {
        clientId: await getConfigValue("clientId"),
        tenant: await getConfigValue("tenant"),
        authorityDomain: await getConfigValue("authorityDomain"),
        redirectUri: await getConfigValue("redirectUri"),
        postLogoutRedirectUri: await getConfigValue("postLogoutRedirectUri"),
        cacheLocation: await getConfigValue("cacheLocation"),
        storeAuthStateInCookie: (await getConfigValue("storeAuthStateInCookie")) === "true",
    };

    return {
        auth: {
            clientId: settings.clientId,
            authority: `https://${settings.authorityDomain}/${settings.tenant}`,
            redirectUri: settings.redirectUri,
            postLogoutRedirectUri: settings.postLogoutRedirectUri,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: settings.cacheLocation,
            storeAuthStateInCookie: settings.storeAuthStateInCookie,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };
};

const loginRequest = {
    scopes: ["User.Read"], // Add necessary scopes here
};

export { fetchConfig, loginRequest };