// src/components/common/UserDropdown/UserDropdown.js

import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import './UserDropdown.css';

const UserDropdown = () => {
    const { instance, accounts } = useMsal();

    const handleLoginRedirect = () => {
        instance.loginRedirect().catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect().catch((error) => {
            console.error('Logout error:', error);
        });
    };

    return (
        <div className="user-dropdown">
            <AuthenticatedTemplate>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-light" id="user-dropdown">
                        <FaUser />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.ItemText>{accounts[0]?.username || "User"}</Dropdown.ItemText>
                        <Dropdown.Divider />
                        <Dropdown.Item as={Link} to="/information">Information</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogoutRedirect}>Sign out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Button variant="outline-light" onClick={handleLoginRedirect}>Sign in</Button>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default UserDropdown;