import axios from 'axios';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance, initializeMsal } from './msalInstance';
import {getConfigValue} from "./authConfig";

// Function to get the base URL
const getBaseUrl = () => {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    return `${protocol}//${hostname}/api`;
};

const scope = await getConfigValue("scope")

const api = axios.create({
    baseURL: getBaseUrl(),
    withCredentials: true,
});

api.interceptors.request.use(async config => {
    try {
        if (!msalInstance) {
            await initializeMsal();
        }
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw new Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
        }
        let tokenResponse;
        try {
            tokenResponse = await msalInstance.acquireTokenSilent({
                scopes: [scope],
                account: account
            });
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                // Fallback to interactive method
                tokenResponse = await msalInstance.acquireTokenPopup({
                    scopes: [scope],
                    account: account
                });
            } else {
                throw error;
            }
        }
        config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
    } catch (error) {
        console.error('Error acquiring token:', error);
        // Handle error (e.g., redirect to login)
        throw new axios.Cancel('Failed to acquire access token');
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Config endpoints
export const getConfig = async () => {
    try {
        const response = await api.get('/config');
        return response.data;
    } catch (error) {
        console.error('Error fetching configuration:', error);
        throw error;
    }
};

export const sendConfigData = (configData) => api.post('/config', configData);

export const getDatabaseConfig = () => api.get('/config/database');
export const updateDatabaseConfig = (config) => api.post('/config/database', config);

export const getMainConfig = () => api.get('/config/main');
export const updateMainConfig = (config) => api.post('/config/main', config);

export const getEmailConfig = () => api.get('/config/email');
export const updateEmailConfig = (config) => api.post('/config/email', config);

export const getSourcesConfig = () => api.get('/config/sources');
export const updateSourcesConfig = (config) => api.post('/config/sources', config);

export const getTargetConfig = () => api.get('/config/target');
export const updateTargetConfig = (config) => api.post('/config/target', config);

export const getKeysConfig = () => api.get('/config/keys');
export const updateKeysConfig = (config) => api.post('/config/keys', config);

// Query endpoints
export const queryTable = (tableName) => api.get(`/query/${tableName}`);
export const queryTableWithConditions = (tableName, conditions) => api.post(`/query/${tableName}/query`, { conditions });

// Count endpoints
export const getCounts = () => api.get('/getCounts');
export const getChangeCount = () => api.get('/getChangeCount');

// Server control endpoints
export const runSync = () => api.post('/sync');
export const generateMappedDataForPreview = () => api.post('/generate-mapped-data')
export const generateGroupsForPreview = () => api.post('/output-groups')
export const restartServer = () => api.post('/restart-server');

export const getGroups = () => api.get("/groups");

// Additional endpoints
export const getServerConfig = () => api.get('/config/server');
export const updateServerConfig = (config) => api.post('/config/server', config);
export const getLogs = (logFile) => api.get(`/logs/${logFile}`);
export const getLogFiles = () => api.get('/logs');
export const getStatistics = () => api.get('/statistics');
export const getRecentRunNumber = () => api.get('/statistics/recent-run');
export const getRecentEndOfRuns = () => api.get('/logs/recent-end-of-runs');
export const getFields = () => api.get(`/fields`);

export default api;