// src/component/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <header className="footerr">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link className="navbar-brand" to="https://aohwv.com">
                            <img src="https://aohwv.com/front/images/logo.png" alt="AOHWV Logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-footer" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent-footer">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item"><Link className="nav-link active" to="https://aohwv.com">Home</Link></li>
                                <li className="nav-item"><Link className="nav-link active" to="https://aohwv.com/about">About Us</Link></li>
                                <li className="nav-item"><Link className="nav-link active" to="https://aohwv.com/service">Services</Link></li>
                                <li className="nav-item"><Link className="nav-link active" to="https://aohwv.com/blog">Blogs</Link></li>
                            </ul>
                        </div>
                    </nav>
                </header>
            </div>
            <hr />
            <div className="container">
                <div className="footer-content">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer-sec fisrt">
                                <h5>About Us</h5>
                                <p><strong>AOHWV</strong> Is The Identity Practice Of <span className="underline">RedLegg</span> with Its Own Identity, Its Own Brand & a Robust Team. Our goal is to build robust Strategy, Architecture, Security, And Everything That Falls In Between.</p>
                                <Link className="btn btn-green" to="https://aohwv.com/capability-statement">Capability Statement</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-sec secnod">
                                <h5>Contact</h5>
                                <ul>
                                    <li><a href="tel:+1-312-731-5750">(312) 731-5750</a></li>
                                    <li className="bdr non"><a href="javascripts:void(0)">Chicago, IL 60642-6421</a></li>
                                    <li><a href="http://www.aohwv.com">www.aohwv.com</a></li>
                                    <li className="bdr"><a href="mailto:lhees@aohwv.com">lhees@aohwv.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-sec thrre">
                                <h5>Friday Challenge</h5>
                                <div className="take-quiz">
                                    <Link to="https://aohwv.com/quiz">Take A Quiz</Link>
                                </div>
                            </div>
                            <div className="social-icons">
                                <ul>
                                    <li><a href="https://www.instagram.com/aoh_iam/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a></li>
                                    <li><a href="https://twitter.com/AOHWV" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/atomic-orbital-hydrogen-wave-vector" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                    <li><a href="https://defcon.social/@LauraHees" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-mastodon"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container">
                <div className="copy-right">
                    <p>2023 aohwv © All rights reserved |
                        <Link to="https://aohwv.com/terms"> Terms of Service</Link> |
                        <Link to="https://aohwv.com/privacy"> Privacy Policy</Link>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;