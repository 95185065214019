import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './PieChart.css';
import { getChangeCount } from '../../../utils/apiService';
import axios from "axios"; // Import the API service

const ChangeCountPieChart = () => {
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: [],
            },
        ],
    });

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getChangeCount();
                const changeCounts = response.data;

                const labels = changeCounts.map(item => item.group_name);
                const eventCounts = changeCounts.map(item => parseInt(item.event_count, 10));
                const backgroundColors = changeCounts.map(() => generateRandomColor());
                const hoverBackgroundColors = changeCounts.map(() => generateRandomColor());

                setData({
                    labels,
                    datasets: [
                        {
                            data: eventCounts,
                            backgroundColor: backgroundColors,
                            hoverBackgroundColor: hoverBackgroundColors,
                        },
                    ],
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
    }, []);

    return (
        <div className="pie-chart-container">
            <Pie data={data} />
        </div>
    );
};

export default ChangeCountPieChart;