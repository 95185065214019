// Configuration.js

import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';

import { getConfig } from '../../utils/apiService'; // Add this function to apiService.js to fetch config data
import './Configuration.css'
import ConfigEditor from "../../components/config/ConfigEditor/ConfigEditor";

const Configuration = () => {
    const [selectedConfig, setSelectedConfig] = useState('keys');
    const [configData, setConfigData] = useState({});

    const configTypes = [
        'keys', 'config', 'emailConfig', 'extMapping', 'logic',
        'mapping', 'report', 'reportTemplate', 'sources', 'target'
    ];

    const configFields = {
        keys: [
            { name: 'apiKey', label: 'API Key', type: 'text', regex: '^[A-Za-z0-9-_]{20,}$' },
            { name: 'secretKey', label: 'Secret Key', type: 'text', regex: '^[A-Za-z0-9-_]{40,}$' }
        ],
        config: [
            { name: 'appName', label: 'Application Name', type: 'text', regex: '^[a-zA-Z0-9_-]{3,50}$' },
            { name: 'version', label: 'Version', type: 'text', regex: '^\\d+\\.\\d+\\.\\d+$' }
        ],
        emailConfig: [
            { name: 'smtpHost', label: 'SMTP Host', type: 'text' },
            { name: 'smtpPort', label: 'SMTP Port', type: 'text', regex: '^[0-9]{1,5}$' },
            { name: 'username', label: 'Username', type: 'text' },
            { name: 'password', label: 'Password', type: 'text' }
        ],
        extMapping: [
            { name: 'txtExt', label: '.txt Extension', type: 'text' },
            { name: 'jpgExt', label: '.jpg Extension', type: 'text' },
            { name: 'pdfExt', label: '.pdf Extension', type: 'text' }
        ],
        logic: [
            { name: 'rules', label: 'Rules', type: 'text' }
        ],
        mapping: [
            { name: 'oldKey', label: 'Old Key', type: 'text' },
            { name: 'newKey', label: 'New Key', type: 'text' }
        ],
        report: [
            { name: 'title', label: 'Report Title', type: 'text' },
            { name: 'sections', label: 'Sections', type: 'text' }
        ],
        reportTemplate: [
            { name: 'header', label: 'Header Template', type: 'text' },
            { name: 'body', label: 'Body Template', type: 'text' },
            { name: 'footer', label: 'Footer Template', type: 'text' }
        ],
        sources: [
            { name: 'databaseName', label: 'Database Name', type: 'text' },
            { name: 'databaseType', label: 'Database Type', type: 'dropdown', options: [
                    { value: 'postgresql', label: 'PostgreSQL' },
                    { value: 'mysql', label: 'MySQL' },
                    { value: 'mongodb', label: 'MongoDB' }
                ]},
            { name: 'connectionString', label: 'Connection String', type: 'text' }
        ],
        target: [
            { name: 'targetName', label: 'Target System Name', type: 'text' },
            { name: 'targetType', label: 'Target System Type', type: 'dropdown', options: [
                    { value: 'mysql', label: 'MySQL' },
                    { value: 'postgresql', label: 'PostgreSQL' },
                    { value: 'oracle', label: 'Oracle' }
                ]},
            { name: 'host', label: 'Host', type: 'text' },
            { name: 'port', label: 'Port', type: 'text', regex: '^[0-9]{1,5}$' },
            { name: 'database', label: 'Database', type: 'text' }
        ]
    };

    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const data = await getConfig();
                setConfigData(data);
            } catch (error) {
                console.error('Error fetching config data:', error);
            }
        };
        fetchConfigData();
    }, []);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div className="configuration-container">
            <div className="sidebar-col">
                <Nav className="flex-column sidebar">
                    {configTypes.map((type) => (
                        <Nav.Item key={type} className="sidebar-item">
                            <Nav.Link
                                onClick={() => setSelectedConfig(type)}
                                active={selectedConfig === type}
                                className={`sidebar-link ${selectedConfig === type ? 'active' : ''}`}
                            >
                                {capitalizeFirstLetter(type)}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </div>
            <div className="content-col">
                <h2>{capitalizeFirstLetter(selectedConfig)}</h2>
                <ConfigEditor
                    fields={configFields[selectedConfig]}
                    initialData={configData[selectedConfig]}
                />
            </div>
        </div>
    );
};

export default Configuration;