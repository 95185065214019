import React, { useState } from 'react';

import './Dashboard.css';
import PieChart from "../../components/charts/PieChart/PieChart";
import ChangeCountPieChart from "../../components/charts/PieChart/ChangeCountPieChart";
import DropdownComponent from "../../components/common/DropdownComponent/DropdownComponent";
import TableComponent from "../../components/data/TableComponent/TableComponent";

function Dashboard() {
    const [selectedTable, setSelectedTable] = useState('joiner');

    const handleTableChange = (table) => {
        setSelectedTable(table);
    };

    return (
        <div className="Dashboard">
            <div className="content">
                <div className="pie-charts">
                    <PieChart />
                    <ChangeCountPieChart />
                </div>
                <div className="table-section">
                    <div className="table-header">
                        <h2>Table</h2>
                        <DropdownComponent
                            options={['joiner', 'mover', 'leaver']}
                            selectedOption={selectedTable}
                            onChange={handleTableChange}
                        />
                    </div>
                    <TableComponent tableName={selectedTable} />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;