// src/component/ConfigEditor.js

import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { sendConfigData } from '../../../utils/apiService';
import './ConfigEditor.css';

const ConfigEditor = ({ fields, initialData }) => {
    const [formData, setFormData] = useState({});

    useEffect(() => {
        setFormData(initialData || {});
    }, [initialData]);

    const handleInputChange = (name, value) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const validateField = (field, value) => {
        if (field.regex && !new RegExp(field.regex).test(value)) {
            return `Invalid format for ${field.label}`;
        }
        return '';
    };

    const renderField = (field) => {
        switch (field.type) {
            case 'text':
                return (
                    <Form.Group as={Row} key={field.name} className="mb-3">
                        <Form.Label column sm={3}>{field.label}</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                type="text"
                                value={formData[field.name] || ''}
                                onChange={(e) => handleInputChange(field.name, e.target.value)}
                                isInvalid={!!validateField(field, formData[field.name])}
                            />
                            <Form.Control.Feedback type="invalid">
                                {validateField(field, formData[field.name])}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                );
            case 'boolean':
                return (
                    <Form.Group as={Row} key={field.name} className="mb-3">
                        <Form.Label column sm={3}>{field.label}</Form.Label>
                        <Col sm={9}>
                            <Form.Check
                                type="switch"
                                id={`switch-${field.name}`}
                                checked={formData[field.name] || false}
                                onChange={(e) => handleInputChange(field.name, e.target.checked)}
                                label={formData[field.name] ? 'Yes' : 'No'}
                            />
                        </Col>
                    </Form.Group>
                );
            case 'dropdown':
                return (
                    <Form.Group as={Row} key={field.name} className="mb-3">
                        <Form.Label column sm={3}>{field.label}</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                as="select"
                                value={formData[field.name] || ''}
                                onChange={(e) => handleInputChange(field.name, e.target.value)}
                            >
                                <option value="">Select an option</option>
                                {field.options.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                );
            default:
                return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await sendConfigData(formData);
            alert('Configuration saved successfully!');
        } catch (error) {
            console.error('Error saving configuration:', error);
            alert('Failed to save configuration. Please try again.');
        }
    };

    return (
        <Form onSubmit={handleSubmit} className="config-editor">
            {fields.map(field => renderField(field))}
            <Row>
                <Col sm={{ span: 9, offset: 3 }}>
                    <Button type="submit" variant="primary" className="mt-3">Save Configuration</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ConfigEditor;