import React from 'react';
import './Information.css'; // Ensure the CSS file is imported

const Information = () => {
    return (
        <div className="information-container">
            <h2>Information Page</h2>
            <p>This page provides information about the HRIT Automation application.</p>
            <section>
                <h3>Section 1</h3>
                <p>Details about section 1.</p>
            </section>
            <section>
                <h3>Section 2</h3>
                <p>Details about section 2.</p>
            </section>
            <section>
                <h3>Section 3</h3>
                <p>Details about section 3.</p>
            </section>
        </div>
    );
};

export default Information;