// src/components/LogViewer.js

import React, { useState, useEffect } from 'react';
import { getLogs, getLogFiles } from '../../../utils/apiService';
import './LogViewer.css';
import DropdownComponent from "../../common/DropdownComponent/DropdownComponent";

const LogViewer = () => {
    const [logContent, setLogContent] = useState('');
    const [logFiles, setLogFiles] = useState([]);
    const [selectedLogFile, setSelectedLogFile] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchLogFiles();
    }, []);

    useEffect(() => {
        if (selectedLogFile) {
            fetchLogContent(selectedLogFile);
        }
    }, [selectedLogFile]);

    const fetchLogFiles = async () => {
        try {
            const files = await getLogFiles();
            setLogFiles(files);
            if (files.length > 0) {
                setSelectedLogFile(files[0]);
            }
        } catch (error) {
            console.error('Error fetching log files:', error);
        }
    };

    const fetchLogContent = async (file) => {
        try {
            const content = await getLogs(file);
            setLogContent(content);
        } catch (error) {
            console.error('Error fetching log content:', error);
        }
    };

    const highlightSearch = (text) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        return text.replace(regex, '<mark>$1</mark>');
    };

    return (
        <div className="log-viewer">
            <DropdownComponent
                options={logFiles}
                selectedOption={selectedLogFile}
                onChange={setSelectedLogFile}
            />
            <input
                type="text"
                placeholder="Search logs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <pre dangerouslySetInnerHTML={{ __html: highlightSearch(logContent) }} />
        </div>
    );
};

export default LogViewer;