// src/components/RecentRunDisplay.js

import React, { useState, useEffect } from 'react';
import { getRecentRunNumber } from '../../../utils/apiService';
import './RecentRunDisplay.css';

const RecentRunDisplay = () => {
    const [recentRun, setRecentRun] = useState(null);

    useEffect(() => {
        fetchRecentRun();
    }, []);

    const fetchRecentRun = async () => {
        try {
            const data = await getRecentRunNumber();
            setRecentRun(data);
        } catch (error) {
            console.error('Error fetching recent run:', error);
        }
    };

    return (
        <div className="recent-run-display">
            <h2>Most Recent Run</h2>
            {recentRun ? (
                <p>Run Number: {recentRun.runNumber}</p>
            ) : (
                <p>No recent run data available</p>
            )}
        </div>
    );
};

export default RecentRunDisplay;