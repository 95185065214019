import { PublicClientApplication } from '@azure/msal-browser';
import { fetchConfig } from './authConfig';

let msalInstance = null;

export const initializeMsal = async () => {
    if (!msalInstance) {
        const msalConfig = await fetchConfig();
        msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();
    }
    return msalInstance;
};

export { msalInstance };