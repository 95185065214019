import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { fetchConfig } from './utils/authConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';
import LoadingScreen from "./components/common/LoadingScreen/LoadingScreen";


const Index = () => {
    const [msalInstance, setMsalInstance] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeMsal = async () => {
            try {
                const msalConfig = await fetchConfig();
                const instance = new PublicClientApplication(msalConfig);

                await instance.initialize(); // Initialize the instance first

                // Handle the redirect response here
                instance.handleRedirectPromise().then(response => {
                    if (response) {
                        instance.setActiveAccount(response.account);
                    }
                });

                if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
                    instance.setActiveAccount(instance.getAllAccounts()[0]);
                }

                instance.addEventCallback((event) => {
                    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                        const account = event.payload.account;
                        instance.setActiveAccount(account);
                    }
                });

                setMsalInstance(instance);
            } catch (error) {
                console.error('Error fetching configuration:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeMsal();
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <Router>
            <App instance={msalInstance} />
        </Router>
    );
};

const root = createRoot(document.getElementById('root'));
root.render(<Index />);