// src/components/EndOfRunDisplay.js

import React, { useState, useEffect } from 'react';
import { getRecentEndOfRuns } from '../../../utils/apiService';
import './EndOfRunDisplay.css';

const EndOfRunDisplay = () => {
    const [endOfRuns, setEndOfRuns] = useState([]);

    useEffect(() => {
        fetchEndOfRuns();
    }, []);

    const fetchEndOfRuns = async () => {
        try {
            const data = await getRecentEndOfRuns();
            setEndOfRuns(data);
        } catch (error) {
            console.error('Error fetching end of runs:', error);
        }
    };

    return (
        <div className="end-of-run-display">
            <h2>Recent End of Runs</h2>
            <ul>
                {endOfRuns.map((run, index) => (
                    <li key={index}>{run.created_at}</li>
                ))}
            </ul>
        </div>
    );
};

export default EndOfRunDisplay;