// src/components/common/NavigationBar/NavigationBar.js

import React, { useState } from 'react';
import { Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../../resources/AOHLogo.png';
import './NavigationBar.css';
import {AuthenticatedTemplate} from "@azure/msal-react";

export const NavigationBar = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => setIsOpen(!isOpen);

    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <Container fluid className="sidebar-content">
                <div className="sidebar-header">
                    <img src={logo} alt="Logo" className="sidebar-logo" />
                    <h1 className="header-title">HR → IT</h1>
                </div>
                <Nav className="flex-column">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <AuthenticatedTemplate>
                        <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link as={Link} to="/config">Configuration</Nav.Link>
                        <Nav.Link as={Link} to="/system-control">Information</Nav.Link>
                        <Nav.Link as={Link} to="/mapping-edditor">Mapping Editor</Nav.Link>
                    </AuthenticatedTemplate>
                </Nav>
            </Container>
            <button onClick={toggleSidebar} className="toggle-btn">
                {isOpen ? '◀' : '▶'}
            </button>
        </div>
    );
};