import React, { useEffect, useState } from 'react';
import './TableComponent.css';
import { queryTable } from '../../../utils/apiService';
import axios from "axios"; // Import the API service

const formatHeader = (header) => {
    if (header === 'id') {
        return header.toUpperCase();
    }
    return header
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const formatData = (header, data) => {
    if (typeof data === 'string') {
        if (header === 'id') {
            return data.toUpperCase();
        } else if (header === 'date') {
            const date = new Date(data);
            const formattedDate = date.toISOString().split('T')[0];
            return formattedDate;
        }
    }
    return data;
};

const TableComponent = ({ tableName }) => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [dateFilter, setDateFilter] = useState({ startDate: '', endDate: '' });
    const [groupNameFilter, setGroupNameFilter] = useState('');
    const [upnFilter, setUpnFilter] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await queryTable(tableName);
                if (response.data.length > 0) {
                    setColumns(Object.keys(response.data[0]));
                }
                setData(response.data);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(`Error fetching data from ${tableName}:`, error);
                }
            }
        };

        fetchData();
    }, [tableName]);

    const handleSort = (column) => {
        let direction = 'ascending';
        if (
            sortConfig.key === column &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key: column, direction: direction });
    };

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig !== null) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const filteredData = React.useMemo(() => {
        let filteredData = sortedData;
        if (dateFilter.startDate) {
            filteredData = filteredData.filter(row => new Date(row.date) >= new Date(dateFilter.startDate));
        }
        if (dateFilter.endDate) {
            filteredData = filteredData.filter(row => new Date(row.date) <= new Date(dateFilter.endDate));
        }
        if (tableName === 'mover' && groupNameFilter) {
            filteredData = filteredData.filter(row => row.group_name.toLowerCase().includes(groupNameFilter.toLowerCase()));
        }
        if (upnFilter) {
            filteredData = filteredData.filter(row => row.upn.toLowerCase().includes(upnFilter.toLowerCase()));
        }
        return filteredData;
    }, [sortedData, dateFilter, groupNameFilter, upnFilter, tableName]);

    return (
        <div className="table-container">
            <h2>{tableName}</h2>
            <div className="filters">
                <div>
                    <label>
                        Start Date:
                        <input
                            type="date"
                            value={dateFilter.startDate}
                            onChange={(e) => setDateFilter({ ...dateFilter, startDate: e.target.value })}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        End Date:
                        <input
                            type="date"
                            value={dateFilter.endDate}
                            onChange={(e) => setDateFilter({ ...dateFilter, endDate: e.target.value })}
                        />
                    </label>
                </div>
                {tableName === 'mover' && (
                    <div>
                        <label>
                            Group Name:
                            <input
                                type="text"
                                value={groupNameFilter}
                                onChange={(e) => setGroupNameFilter(e.target.value)}
                            />
                        </label>
                    </div>
                )}
                <div>
                    <label>
                        UPN:
                        <input
                            type="text"
                            value={upnFilter}
                            onChange={(e) => setUpnFilter(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            <div className="table-wrapper">
                <table>
                    <thead>
                    <tr>
                        {columns.map((column) => (
                            <th key={column} onClick={() => handleSort(column)}>
                                {formatHeader(column)}
                                {sortConfig.key === column ? (
                                    sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                                ) : null}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {filteredData.map((row, index) => (
                        <tr key={index}>
                            {columns.map((column) => (
                                <td key={column}>{formatData(column, row[column])}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableComponent;