// src/pages/SystemControl.js

import React, { useState, useEffect } from 'react';
import { getServerConfig, runSync, restartServer } from '../../utils/apiService';
import './SystemControl.css';
import ConfigEditor from "../../components/config/ConfigEditor/ConfigEditor";
import LogViewer from "../../components/logs/LogViewer/LogViewer";
import StatisticsTable from "../../components/data/StatisticsTable/StatisticsTable";
import RecentRunDisplay from "../../components/data/RecentRunDisplay/RecentRunDisplay";
import EndOfRunDisplay from "../../components/data/EndOfRunDisplay/EndOfRunDisplay";

const SystemControl = () => {
    const [serverConfig, setServerConfig] = useState({});

    useEffect(() => {
        fetchServerConfig();
    }, []);

    const fetchServerConfig = async () => {
        try {
            const config = await getServerConfig();
            setServerConfig(config);
        } catch (error) {
            console.error('Error fetching server config:', error);
        }
    };

    const handleRunJar = async () => {
        try {
            await runSync();
            alert('Jar run initiated successfully');
        } catch (error) {
            console.error('Error running jar:', error);
            alert('Failed to run jar');
        }
    };

    const handleRestartServer = async () => {
        try {
            await restartServer();
            alert('Server restart initiated');
        } catch (error) {
            console.error('Error restarting server:', error);
            alert('Failed to restart server');
        }
    };

    return (
        <div className="system-control">
            <h1>System Control</h1>
            <div className="control-buttons">
                <button onClick={handleRunJar}>Run Jar</button>
                <button onClick={handleRestartServer}>Restart Server</button>
            </div>
            <ConfigEditor fields={serverConfigFields} initialData={serverConfig} />
            <LogViewer />
            <StatisticsTable />
            <RecentRunDisplay />
            <EndOfRunDisplay />
        </div>
    );
};

const serverConfigFields = [
    { name: 'port', label: 'Port', type: 'text', regex: '^[0-9]+$' },
    // Add more fields as needed
];

export default SystemControl;