// src/components/common/PageLayout/PageLayout.js

import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from '../NavigationBar/NavigationBar.js';
import UserDropdown from '../UserDropdown/UserDropdown';
import './PageLayout.css';

export const PageLayout = ({ children }) => {
    return (
        <div className="page-layout">
            <NavigationBar />
            <UserDropdown />
            <main className="main-content">
                {children}
            </main>
            <footer className="footer">
                <AuthenticatedTemplate>
                    <center>
                        {/* Footer content */}
                    </center>
                </AuthenticatedTemplate>
            </footer>
        </div>
    );
}