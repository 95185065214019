import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Login = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        if (accounts.length > 0) {
            // User is already logged in, store the account information in cookies
            Cookies.set('msalAccount', accounts[0].homeAccountId, { sameSite: 'None', secure: true });
            navigate('/');
        } else if (inProgress === 'none') {
            // User is not logged in, initiate login
            instance.loginRedirect().catch((error) => {
                console.error('Login error', error);
            });
        }
    }, [instance, accounts, inProgress, navigate]);

    return <div>Loading...</div>;
};

export default Login;