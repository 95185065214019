import React from 'react';
import logo from '../../../resources/AOHLogo.png';
import './LoadingScreen.css';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <div className="loading-content">
                <h1 className="welcome-message">Welcome to AOH WV HR IT Automation Dashboard</h1>
                <img src={logo} alt="AOH Logo" className="logo" />
                <div className="spinner"></div>
                <p>Loading...</p>
            </div>
        </div>
    );
};

export default LoadingScreen;