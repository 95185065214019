// src/components/StatisticsTable.js

import React, { useState, useEffect } from 'react';
import { getStatistics } from '../../../utils/apiService';
import './StatisticsTable.css';

const StatisticsTable = () => {
    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        fetchStatistics();
    }, []);

    const fetchStatistics = async () => {
        try {
            const data = await getStatistics();
            setStatistics(data);
        } catch (error) {
            console.error('Error fetching statistics:', error);
        }
    };

    return (
        <div className="statistics-table">
            <h2>Statistics</h2>
            <table>
                <thead>
                <tr>
                    <th>Time</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {statistics.map((stat, index) => (
                    <tr key={index}>
                        <td>{stat.time}</td>
                        <td>{stat.value}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default StatisticsTable;